import React, { useState, useRef, useCallback } from 'react';
import CForm from './components/form';
import Card from './components/card';
import { formStateHasError } from './helpers/validators';

const initialState = {
    cardNumber: '#### #### #### ####',
    cardHolder: 'NOMBRE COMPLETO',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
    isCardFlipped: false
};

const validateIsLoggedInCliengo = () => {
    if(!document.cookie.includes("jwt=")){
        console.log('No está logueado en cliengo')
        window.location.href = process.env.REACT_APP_LOGIN_URL + '/?redirect='+process.env.REACT_APP_CHECKOUT_URL;
    }
};

const MainScreen = () => {

    validateIsLoggedInCliengo()

    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [currentFocusedElm, setCurrentFocusedElm] = useState(null);
    
    const updateStateValues = useCallback(
        (keyName, value) => {
            setState({
                ...state,
                [keyName]: value || initialState[keyName]
            });
        },
        [state]
    );

    // References for the Form Inputs used to focus corresponding inputs.
    let formFieldsRefObj = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCvv: useRef()
    };

    let focusFormFieldByKey = useCallback((key) => {
        formFieldsRefObj[key].current.focus();
    });

    // This are the references for the Card DIV elements.
    let cardElementsRef = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef()
    };

    let onCardFormInputFocus = (_event, inputName) => {
        const refByName = cardElementsRef[inputName];
        setCurrentFocusedElm(refByName);
    };

    let onCardInputBlur = useCallback(() => {
        setCurrentFocusedElm(null);
    }, []);

    let doSubmit = () => {
        
        const hasErrors = formStateHasError(state)
        
        if (hasErrors) {
            setErrors(hasErrors)
            console.log('tiene errores')
            return;
        }
        setErrors({})
        
        fetch(process.env.REACT_APP_CHECKOUT_BACKEND+'/addCard', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({state})
        })
        
        //Oculta el FORM
        document.getElementById('confirmation-msg-id').style.display='block'


        return ;
    };

    return (
        <div className="wrapper">
            <CForm
                handleSubmit={doSubmit}
                cardMonth={state.cardMonth}
                cardYear={state.cardYear}
                onUpdateState={updateStateValues}
                cardNumberRef={formFieldsRefObj.cardNumber}
                cardHolderRef={formFieldsRefObj.cardHolder}
                cardDateRef={formFieldsRefObj.cardDate}
                onCardInputFocus={onCardFormInputFocus}
                onCardInputBlur={onCardInputBlur}
                errors={errors}
            >

                <Card
                    cardNumber={state.cardNumber}
                    cardHolder={state.cardHolder}
                    cardMonth={state.cardMonth}
                    cardYear={state.cardYear}
                    cardCvv={state.cardCvv}
                    isCardFlipped={state.isCardFlipped}
                    currentFocusedElm={currentFocusedElm}
                    onCardElementClick={focusFormFieldByKey}
                    cardNumberRef={cardElementsRef.cardNumber}
                    cardHolderRef={cardElementsRef.cardHolder}
                    cardDateRef={cardElementsRef.cardDate}
                ></Card>
            </CForm>
            <div id="confirmation-msg-id" className="confirmation-msg" >
                Muchas gracias. <br/>
                Estaremos procesando tu pago.</div>
        </div>
    );
};

export default MainScreen;
