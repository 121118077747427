export const isOnlyNumbers = (value) => {
    return /^\d+$/.test(value);
};

export const isValidCardFormat = (cardNumber) => {
    return /^\d{4} \d{4} \d{4} \d{4}$/.test(cardNumber) ||  /^\d{4} \d{6} \d{5}$/.test(cardNumber) ; 
}

export const validateCardNumber = (cardNumber) => {
    if (!cardNumber) {
        return 'El número de tarjeta es requerido';
    }
    if (cardNumber.replaceAll(' ', '').length < 15) { 
        return 'El número de tarjeta debe tener 15 o 16 dígitos';
    }
    if (!isValidCardFormat(cardNumber)) {
        return 'El número de tarjeta debe ser solo números y espacios';
    }
    return '';
};

export const validateCardHolder = (cardHolder) => {
    if (!cardHolder || cardHolder === 'NOMBRE COMPLETO') {
        return 'El nombre del titular es requerido';
    }
    // must be only letters and latin characters
    if (!/^[a-zA-Z ]+$/.test(cardHolder)) {
        return 'El nombre del titular debe ser solo letras';
    }
    return '';
};

export const validateCardMonth = (cardMonth) => {
    if (!cardMonth) {
        return 'El mes de expiración es requerido';
    }
    if (!isOnlyNumbers(cardMonth)) {
        return 'El mes de expiración debe ser solo números';
    }
    if (cardMonth.length !== 2) {
        return 'El mes de expiración debe tener 2 dígitos';
    }
    return '';
};

export const validateCardYear = (cardYear) => {
    if (!cardYear) {
        return 'El año de expiración es requerido';
    }
    if (!isOnlyNumbers(cardYear)) {
        return 'El año de expiración debe ser solo números';
    }
    if (cardYear.length !== 2) {
        return 'El año de expiración no es válido';
    }
    return '';
};

export const validateCardCvv = (cardCvv) => {
    if (!cardCvv) {
        return 'El CVV es requerido';
    }
    if (!isOnlyNumbers(cardCvv)) {
        return 'El CVV debe ser solo números';
    }
    if (cardCvv.length < 3 ) {
        return 'El CVV debe al menos 3 dígitos';
    }
    return '';
};

/**
 * @param {{
 *  cardCvv: string,
 *  cardHolder: string,
 *  cardMonth: string,
 *  cardNumber: string,
 *  cardYear: string,
 *  isCardFlipped: boolean
 * }} state
 * @returns {{
 *  cardCvv: string | null,
 *  cardHolder: string | null,
 *  cardMonth: string | null,
 *  cardNumber: string | null,
 *  cardYear: string | null,
 * }} true if all fields are valid
 */
export const formStateHasError = (state) => {
    const { cardCvv, cardHolder, cardMonth, cardNumber, cardYear } = state;

    const cardNumberError = validateCardNumber(cardNumber) || null;
    const cardHolderError = validateCardHolder(cardHolder) || null;
    const cardMonthError = validateCardMonth(cardMonth) || null;
    const cardYearError = validateCardYear(cardYear) || null;
    const cardCvvError = validateCardCvv(cardCvv) || null;

    if (
        !cardNumberError &&
        !cardHolderError &&
        !cardMonthError &&
        !cardYearError &&
        !cardCvvError
    ) {
        return false;
    }

    return {
        cardCvv: cardCvvError,
        cardNumber: cardNumberError,
        cardHolder: cardHolderError,
        cardMonth: cardMonthError,
        cardYear: cardYearError,
    };
};
